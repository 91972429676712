@font-face {
  font-family: 'fontello';
  src: url('../../font/fontello.eot?18744287');
  src: url('../../font/fontello.eot?18744287#iefix') format('embedded-opentype'),
       url('../../font/fontello.woff2?18744287') format('woff2'),
       url('../../font/fontello.woff?18744287') format('woff'),
       url('../../font/fontello.ttf?18744287') format('truetype'),
       url('../../font/fontello.svg?18744287#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?18744287#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-note-beamed:before { content: '\e800'; } /* '' */
.icon-flash:before { content: '\e801'; } /* '' */
.icon-moon:before { content: '\e802'; } /* '' */
.icon-flight:before { content: '\e803'; } /* '' */
.icon-paper-plane:before { content: '\e804'; } /* '' */
.icon-leaf:before { content: '\e805'; } /* '' */
.icon-lifebuoy:before { content: '\e806'; } /* '' */
.icon-mouse:before { content: '\e807'; } /* '' */
.icon-briefcase:before { content: '\e808'; } /* '' */
.icon-suitcase:before { content: '\e809'; } /* '' */
.icon-dot:before { content: '\e80a'; } /* '' */
.icon-dot-2:before { content: '\e80b'; } /* '' */
.icon-dot-3:before { content: '\e80c'; } /* '' */
.icon-brush:before { content: '\e80d'; } /* '' */
.icon-magnet:before { content: '\e80e'; } /* '' */
.icon-infinity:before { content: '\e80f'; } /* '' */
.icon-erase:before { content: '\e810'; } /* '' */
.icon-chart-pie:before { content: '\e811'; } /* '' */
.icon-chart-line:before { content: '\e812'; } /* '' */
.icon-chart-bar:before { content: '\e813'; } /* '' */
.icon-chart-area:before { content: '\e814'; } /* '' */
.icon-tape:before { content: '\e815'; } /* '' */
.icon-graduation-cap:before { content: '\e816'; } /* '' */
.icon-language:before { content: '\e817'; } /* '' */
.icon-ticket:before { content: '\e818'; } /* '' */
.icon-water:before { content: '\e819'; } /* '' */
.icon-droplet:before { content: '\e81a'; } /* '' */
.icon-air:before { content: '\e81b'; } /* '' */
.icon-credit-card:before { content: '\e81c'; } /* '' */
.icon-floppy:before { content: '\e81d'; } /* '' */
.icon-clipboard:before { content: '\e81e'; } /* '' */
.icon-megaphone:before { content: '\e81f'; } /* '' */
.icon-database:before { content: '\e820'; } /* '' */
.icon-drive:before { content: '\e821'; } /* '' */
.icon-bucket:before { content: '\e822'; } /* '' */
.icon-thermometer:before { content: '\e823'; } /* '' */
.icon-key:before { content: '\e824'; } /* '' */
.icon-flow-cascade:before { content: '\e825'; } /* '' */
.icon-flow-branch:before { content: '\e826'; } /* '' */
.icon-flow-tree:before { content: '\e827'; } /* '' */
.icon-flow-line:before { content: '\e828'; } /* '' */
.icon-flow-parallel:before { content: '\e829'; } /* '' */
.icon-rocket:before { content: '\e82a'; } /* '' */
.icon-gauge:before { content: '\e82b'; } /* '' */
.icon-traffic-cone:before { content: '\e82c'; } /* '' */
.icon-cc:before { content: '\e82d'; } /* '' */
.icon-cc-by:before { content: '\e82e'; } /* '' */
.icon-cc-nc:before { content: '\e82f'; } /* '' */
.icon-cc-nc-eu:before { content: '\e830'; } /* '' */
.icon-cc-nc-jp:before { content: '\e831'; } /* '' */
.icon-cc-sa:before { content: '\e832'; } /* '' */
.icon-cc-nd:before { content: '\e833'; } /* '' */
.icon-cc-pd:before { content: '\e834'; } /* '' */
.icon-cc-zero:before { content: '\e835'; } /* '' */
.icon-cc-share:before { content: '\e836'; } /* '' */
.icon-cc-remix:before { content: '\e837'; } /* '' */
.icon-music:before { content: '\e838'; } /* '' */
.icon-search:before { content: '\e839'; } /* '' */
.icon-flashlight:before { content: '\e83a'; } /* '' */
.icon-mail:before { content: '\e83b'; } /* '' */
.icon-heart:before { content: '\e83c'; } /* '' */
.icon-heart-empty:before { content: '\e83d'; } /* '' */
.icon-star:before { content: '\e83e'; } /* '' */
.icon-star-empty:before { content: '\e83f'; } /* '' */
.icon-user:before { content: '\e840'; } /* '' */
.icon-users:before { content: '\e841'; } /* '' */
.icon-user-add:before { content: '\e842'; } /* '' */
.icon-video:before { content: '\e843'; } /* '' */
.icon-picture:before { content: '\e844'; } /* '' */
.icon-camera:before { content: '\e845'; } /* '' */
.icon-layout:before { content: '\e846'; } /* '' */
.icon-menu:before { content: '\e847'; } /* '' */
.icon-check:before { content: '\e848'; } /* '' */
.icon-cancel:before { content: '\e849'; } /* '' */
.icon-cancel-circled:before { content: '\e84a'; } /* '' */
.icon-cancel-squared:before { content: '\e84b'; } /* '' */
.icon-plus:before { content: '\e84c'; } /* '' */
.icon-plus-circled:before { content: '\e84d'; } /* '' */
.icon-plus-squared:before { content: '\e84e'; } /* '' */
.icon-minus:before { content: '\e84f'; } /* '' */
.icon-minus-circled:before { content: '\e850'; } /* '' */
.icon-minus-squared:before { content: '\e851'; } /* '' */
.icon-help:before { content: '\e852'; } /* '' */
.icon-help-circled:before { content: '\e853'; } /* '' */
.icon-info:before { content: '\e854'; } /* '' */
.icon-info-circled:before { content: '\e855'; } /* '' */
.icon-back:before { content: '\e856'; } /* '' */
.icon-home:before { content: '\e857'; } /* '' */
.icon-link:before { content: '\e858'; } /* '' */
.icon-attach:before { content: '\e859'; } /* '' */
.icon-lock:before { content: '\e85a'; } /* '' */
.icon-lock-open:before { content: '\e85b'; } /* '' */
.icon-eye:before { content: '\e85c'; } /* '' */
.icon-tag:before { content: '\e85d'; } /* '' */
.icon-bookmark:before { content: '\e85e'; } /* '' */
.icon-bookmarks:before { content: '\e85f'; } /* '' */
.icon-flag:before { content: '\e860'; } /* '' */
.icon-thumbs-up:before { content: '\e861'; } /* '' */
.icon-thumbs-down:before { content: '\e862'; } /* '' */
.icon-download:before { content: '\e863'; } /* '' */
.icon-upload:before { content: '\e864'; } /* '' */
.icon-upload-cloud:before { content: '\e865'; } /* '' */
.icon-reply:before { content: '\e866'; } /* '' */
.icon-reply-all:before { content: '\e867'; } /* '' */
.icon-forward:before { content: '\e868'; } /* '' */
.icon-quote:before { content: '\e869'; } /* '' */
.icon-code:before { content: '\e86a'; } /* '' */
.icon-export:before { content: '\e86b'; } /* '' */
.icon-pencil:before { content: '\e86c'; } /* '' */
.icon-feather:before { content: '\e86d'; } /* '' */
.icon-print:before { content: '\e86e'; } /* '' */
.icon-retweet:before { content: '\e86f'; } /* '' */
.icon-keyboard:before { content: '\e870'; } /* '' */
.icon-comment:before { content: '\e871'; } /* '' */
.icon-chat:before { content: '\e872'; } /* '' */
.icon-bell:before { content: '\e873'; } /* '' */
.icon-attention:before { content: '\e874'; } /* '' */
.icon-alert:before { content: '\e875'; } /* '' */
.icon-vcard:before { content: '\e876'; } /* '' */
.icon-address:before { content: '\e877'; } /* '' */
.icon-location:before { content: '\e878'; } /* '' */
.icon-map:before { content: '\e879'; } /* '' */
.icon-direction:before { content: '\e87a'; } /* '' */
.icon-compass:before { content: '\e87b'; } /* '' */
.icon-cup:before { content: '\e87c'; } /* '' */
.icon-trash:before { content: '\e87d'; } /* '' */
.icon-doc:before { content: '\e87e'; } /* '' */
.icon-docs:before { content: '\e87f'; } /* '' */
.icon-doc-landscape:before { content: '\e880'; } /* '' */
.icon-doc-text:before { content: '\e881'; } /* '' */
.icon-doc-text-inv:before { content: '\e882'; } /* '' */
.icon-newspaper:before { content: '\e883'; } /* '' */
.icon-book-open:before { content: '\e884'; } /* '' */
.icon-book:before { content: '\e885'; } /* '' */
.icon-folder:before { content: '\e886'; } /* '' */
.icon-archive:before { content: '\e887'; } /* '' */
.icon-box:before { content: '\e888'; } /* '' */
.icon-rss:before { content: '\e889'; } /* '' */
.icon-phone:before { content: '\e88a'; } /* '' */
.icon-cog:before { content: '\e88b'; } /* '' */
.icon-tools:before { content: '\e88c'; } /* '' */
.icon-share:before { content: '\e88d'; } /* '' */
.icon-shareable:before { content: '\e88e'; } /* '' */
.icon-basket:before { content: '\e88f'; } /* '' */
.icon-bag:before { content: '\e890'; } /* '' */
.icon-calendar:before { content: '\e891'; } /* '' */
.icon-login:before { content: '\e892'; } /* '' */
.icon-logout:before { content: '\e893'; } /* '' */
.icon-mic:before { content: '\e894'; } /* '' */
.icon-mute:before { content: '\e895'; } /* '' */
.icon-sound:before { content: '\e896'; } /* '' */
.icon-volume:before { content: '\e897'; } /* '' */
.icon-clock:before { content: '\e898'; } /* '' */
.icon-hourglass:before { content: '\e899'; } /* '' */
.icon-lamp:before { content: '\e89a'; } /* '' */
.icon-light-down:before { content: '\e89b'; } /* '' */
.icon-light-up:before { content: '\e89c'; } /* '' */
.icon-adjust:before { content: '\e89d'; } /* '' */
.icon-block:before { content: '\e89e'; } /* '' */
.icon-resize-full:before { content: '\e89f'; } /* '' */
.icon-resize-small:before { content: '\e8a0'; } /* '' */
.icon-popup:before { content: '\e8a1'; } /* '' */
.icon-publish:before { content: '\e8a2'; } /* '' */
.icon-window:before { content: '\e8a3'; } /* '' */
.icon-arrow-combo:before { content: '\e8a4'; } /* '' */
.icon-down-circled:before { content: '\e8a5'; } /* '' */
.icon-left-circled:before { content: '\e8a6'; } /* '' */
.icon-right-circled:before { content: '\e8a7'; } /* '' */
.icon-up-circled:before { content: '\e8a8'; } /* '' */
.icon-down-open:before { content: '\e8a9'; } /* '' */
.icon-left-open:before { content: '\e8aa'; } /* '' */
.icon-right-open:before { content: '\e8ab'; } /* '' */
.icon-up-open:before { content: '\e8ac'; } /* '' */
.icon-down-open-mini:before { content: '\e8ad'; } /* '' */
.icon-left-open-mini:before { content: '\e8ae'; } /* '' */
.icon-right-open-mini:before { content: '\e8af'; } /* '' */
.icon-up-open-mini:before { content: '\e8b0'; } /* '' */
.icon-down-open-big:before { content: '\e8b1'; } /* '' */
.icon-left-open-big:before { content: '\e8b2'; } /* '' */
.icon-right-open-big:before { content: '\e8b3'; } /* '' */
.icon-up-open-big:before { content: '\e8b4'; } /* '' */
.icon-down:before { content: '\e8b5'; } /* '' */
.icon-left:before { content: '\e8b6'; } /* '' */
.icon-right:before { content: '\e8b7'; } /* '' */
.icon-up:before { content: '\e8b8'; } /* '' */
.icon-down-dir:before { content: '\e8b9'; } /* '' */
.icon-left-dir:before { content: '\e8ba'; } /* '' */
.icon-right-dir:before { content: '\e8bb'; } /* '' */
.icon-up-dir:before { content: '\e8bc'; } /* '' */
.icon-down-bold:before { content: '\e8bd'; } /* '' */
.icon-left-bold:before { content: '\e8be'; } /* '' */
.icon-right-bold:before { content: '\e8bf'; } /* '' */
.icon-up-bold:before { content: '\e8c0'; } /* '' */
.icon-down-thin:before { content: '\e8c1'; } /* '' */
.icon-left-thin:before { content: '\e8c2'; } /* '' */
.icon-right-thin:before { content: '\e8c3'; } /* '' */
.icon-up-thin:before { content: '\e8c4'; } /* '' */
.icon-ccw:before { content: '\e8c5'; } /* '' */
.icon-cw:before { content: '\e8c6'; } /* '' */
.icon-arrows-ccw:before { content: '\e8c7'; } /* '' */
.icon-level-down:before { content: '\e8c8'; } /* '' */
.icon-level-up:before { content: '\e8c9'; } /* '' */
.icon-shuffle:before { content: '\e8ca'; } /* '' */
.icon-loop:before { content: '\e8cb'; } /* '' */
.icon-switch:before { content: '\e8cc'; } /* '' */
.icon-play:before { content: '\e8cd'; } /* '' */
.icon-stop:before { content: '\e8ce'; } /* '' */
.icon-pause:before { content: '\e8cf'; } /* '' */
.icon-record:before { content: '\e8d0'; } /* '' */
.icon-to-end:before { content: '\e8d1'; } /* '' */
.icon-to-start:before { content: '\e8d2'; } /* '' */
.icon-fast-forward:before { content: '\e8d3'; } /* '' */
.icon-fast-backward:before { content: '\e8d4'; } /* '' */
.icon-progress-0:before { content: '\e8d5'; } /* '' */
.icon-progress-1:before { content: '\e8d6'; } /* '' */
.icon-progress-2:before { content: '\e8d7'; } /* '' */
.icon-progress-3:before { content: '\e8d8'; } /* '' */
.icon-target:before { content: '\e8d9'; } /* '' */
.icon-palette:before { content: '\e8da'; } /* '' */
.icon-list:before { content: '\e8db'; } /* '' */
.icon-list-add:before { content: '\e8dc'; } /* '' */
.icon-signal:before { content: '\e8dd'; } /* '' */
.icon-trophy:before { content: '\e8de'; } /* '' */
.icon-battery:before { content: '\e8df'; } /* '' */
.icon-back-in-time:before { content: '\e8e0'; } /* '' */
.icon-monitor:before { content: '\e8e1'; } /* '' */
.icon-mobile:before { content: '\e8e2'; } /* '' */
.icon-network:before { content: '\e8e3'; } /* '' */
.icon-cd:before { content: '\e8e4'; } /* '' */
.icon-inbox:before { content: '\e8e5'; } /* '' */
.icon-install:before { content: '\e8e6'; } /* '' */
.icon-globe:before { content: '\e8e7'; } /* '' */
.icon-cloud:before { content: '\e8e8'; } /* '' */
.icon-cloud-thunder:before { content: '\e8e9'; } /* '' */
.icon-note:before { content: '\e8ea'; } /* '' */
.icon-github:before { content: '\f300'; } /* '' */
.icon-github-circled:before { content: '\f301'; } /* '' */
.icon-flickr:before { content: '\f303'; } /* '' */
.icon-flickr-circled:before { content: '\f304'; } /* '' */
.icon-vimeo:before { content: '\f306'; } /* '' */
.icon-vimeo-circled:before { content: '\f307'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
.icon-twitter-circled:before { content: '\f30a'; } /* '' */
.icon-facebook:before { content: '\f30c'; } /* '' */
.icon-facebook-circled:before { content: '\f30d'; } /* '' */
.icon-facebook-squared:before { content: '\f30e'; } /* '' */
.icon-gplus:before { content: '\f30f'; } /* '' */
.icon-gplus-circled:before { content: '\f310'; } /* '' */
.icon-pinterest:before { content: '\f312'; } /* '' */
.icon-pinterest-circled:before { content: '\f313'; } /* '' */
.icon-tumblr:before { content: '\f315'; } /* '' */
.icon-tumblr-circled:before { content: '\f316'; } /* '' */
.icon-linkedin:before { content: '\f318'; } /* '' */
.icon-linkedin-circled:before { content: '\f319'; } /* '' */
.icon-dribbble:before { content: '\f31b'; } /* '' */
.icon-dribbble-circled:before { content: '\f31c'; } /* '' */
.icon-stumbleupon:before { content: '\f31e'; } /* '' */
.icon-stumbleupon-circled:before { content: '\f31f'; } /* '' */
.icon-lastfm:before { content: '\f321'; } /* '' */
.icon-lastfm-circled:before { content: '\f322'; } /* '' */
.icon-rdio:before { content: '\f324'; } /* '' */
.icon-rdio-circled:before { content: '\f325'; } /* '' */
.icon-spotify:before { content: '\f327'; } /* '' */
.icon-spotify-circled:before { content: '\f328'; } /* '' */
.icon-qq:before { content: '\f32a'; } /* '' */
.icon-instagram:before { content: '\f32d'; } /* '' */
.icon-dropbox:before { content: '\f330'; } /* '' */
.icon-evernote:before { content: '\f333'; } /* '' */
.icon-flattr:before { content: '\f336'; } /* '' */
.icon-skype:before { content: '\f339'; } /* '' */
.icon-skype-circled:before { content: '\f33a'; } /* '' */
.icon-renren:before { content: '\f33c'; } /* '' */
.icon-sina-weibo:before { content: '\f33f'; } /* '' */
.icon-paypal:before { content: '\f342'; } /* '' */
.icon-picasa:before { content: '\f345'; } /* '' */
.icon-soundcloud:before { content: '\f348'; } /* '' */
.icon-mixi:before { content: '\f34b'; } /* '' */
.icon-behance:before { content: '\f34e'; } /* '' */
.icon-google-circles:before { content: '\f351'; } /* '' */
.icon-vkontakte:before { content: '\f354'; } /* '' */
.icon-smashing:before { content: '\f357'; } /* '' */
.icon-db-shape:before { content: '\f600'; } /* '' */
.icon-sweden:before { content: '\f601'; } /* '' */
.icon-logo-db:before { content: '\f603'; } /* '' */
