.TrackListItemHandle-container,
.TrackListItemHandle-sortableContainer {
  width: 30px;
}

.TrackListItemHandle-sortableContainer:hover .TrackListItemHandle-sortHandle,
.TrackListItem-helper .TrackListItemHandle-sortHandle {
  display: block;
}

.TrackListItemHandle-sortableContainer:hover .TrackListItemHandle-index,
.TrackListItem-helper .TrackListItemHandle-index {
  display: none;
}

.TrackListItemHandle-sortHandle {
  display: none;
}

.TrackListItemHandle-sortHandle:before {
  margin-left: -7px;
}

.TrackListItemHandle-index {
  font-size: 0.8em;
  color: var(--white-50);
}

.TrackListItemHandle-nowPlayingIcon {
  width: 12px;
  height: 10px;
  display: flex;
  margin-right: 1em;
}
