.AlbumListHeader {
  color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 40px;
}

.AlbumListHeader-title {
  position: relative;
  z-index: 1;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 1em;
}
